<main class="home">
    <section class="hero">
        <div class="container">
            <div class="hero__header d-flex align-items-center justify-content-space-between header">
                <a href="/" class="header__logo">
                    <img src="assets/images/logo.png" alt="App Logo" height="1%" width="35%" />
                </a>

                <div class="header__right d-flex align-items-center">
                    <a href="/register" class="regiter-btn">Register</a>

                    <a href="/login" class="login-btn">Login</a>
                </div>
            </div>

            <div class="hero__content d-flex-column align-items-center">
                <h1 class="hero__title">Writing cheques has never been this easy</h1>

                <p class="hero__subtitle">
                    EasyCheque makes managing payments to your suppliers simple and efficient. It allows you to write, print cheques, and
                    track your expenses effortlessly.
                </p>
            </div>

            <div class="hero-banner">
                <img src="assets/images/blank-check.jpg" alt="Blank check" class="hero-banner__img" />
            </div>
        </div>
    </section>

    <footer class="footer">
        <div class="container">
            <section class="subscribe d-flex align-items-center">
                <div class="subscribe__left">
                    <h2 class="subscribe__title">
                        Subscribe Our Newsletter
                        <span>get reguler updates</span>
                    </h2>
                </div>

                <div class="subscribe__right">
                    <form [formGroup]="subscribeForm" class="subscribe__form">
                        <input type="text" placeholder="Enter email" />
                        <button class="subscribe__btn">Subscribe</button>
                    </form>
                </div>
            </section>

            <div class="footer-links d-flex">
                <div class="social-icons">
                    <ul class="social-icons__list d-flex align-items-center">
                        <li class="social-icons__item">
                            <a href="javascript:void(0)" class="social-icons__link">
                                <i class="lni lni-facebook-filled"></i>
                            </a>
                        </li>

                        <li class="social-icons__item">
                            <a href="javascript:void(0)" class="social-icons__link">
                                <i class="lni lni-twitter-filled"></i>
                            </a>
                        </li>

                        <li class="social-icons__item">
                            <a href="javascript:void(0)" class="social-icons__link">
                                <i class="lni lni-instagram-filled"></i>
                            </a>
                        </li>

                        <li class="social-icons__item">
                            <a href="javascript:void(0)" class="social-icons__link">
                                <i class="lni lni-linkedin-original"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="quick-links d-flex align-items-end justify-content-center">
                    <ul class="quick-links__list d-flex align-items-end justify-content-center">
                        <li class="quick-links__item">
                            <a href="javascript:void(0)" class="quick-links__link"> Privacy Policy </a>
                        </li>

                        <li class="quick-links__item">
                            <a href="javascript:void(0)" class="quick-links__link"> Terms of Service </a>
                        </li>
                    </ul>
                </div>

                <div class="empty">&nbsp;</div>
            </div>
        </div>
    </footer>
</main>
